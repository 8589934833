<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-spreadsheet" />
            <strong> Country</strong>
          </CCardHeader>
          <CCardBody>
            <TB :tb_config="tb">
              <e-columns>
                <e-column
                  field="code"
                  headerText="code"
                  type="string"
                  :isPrimaryKey="true"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="name"
                  headerText="name"
                  type="string"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="currency.code"
                  headerText="currency"
                  type="string"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="currency_alt.code"
                  headerText="alt. currency"
                  type="string"
                  :edit="dpParams"
                ></e-column>
                <e-column
                  field="languages.name"
                  headerText="langs"
                  type="string"
                  :template="langTemplate"
                  :allowEditing="false"
                ></e-column>
                <e-column
                  field="status"
                  headerText="stato"
                  type="number"
                  :allowEditing="false"
                ></e-column>
              </e-columns>
            </TB>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { DataManager } from "@syncfusion/ej2-data";
import DrfAdaptor from "../../../http/EJ2_adapter";
// import { MultiSelectPlugin, MultiSelect } from "@syncfusion/ej2-vue-dropdowns";

import TB from "../../../common/TB";
import { apiUrl } from "../../../http";

export default Vue.extend({
  name: "Country",
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: new DataManager({
          url: apiUrl(`/api/geo/country/`),
          adaptor: new DrfAdaptor(),
          crossDomain: true,
        }),
        toolbarOptions: [
          // {
          //   text: "Refresh",
          //   tooltipText: "Refresh data",
          //   prefixIcon: "e-expand",
          //   id: "refresh",
          // },
          // "Add",
          "Edit",
          // "Delete",
          // "Update",
          "Cancel",
          "Search",
          "ColumnChooser",
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Dialog",
        },
      },
      dpParams: {},
      // dpParams: {
      //   create: function () {
      //     elem = document.createElement("input");
      //     return elem;
      //   },
      //   read: () => {
      //     return multiSelectObj.value;
      //   },
      //   destroy: () => {
      //     multiSelectObj.destroy();
      //   },
      //   write: (args) => {
      //     var data = args.rowData.currency_alt;
      //     multiSelectObj = new MultiSelect({
      //       // value:
      //       //   typeof data === "string"
      //       //     ? args.rowData.currency_alt.split()
      //       //     : data,
      //       fields: { text: "name", value: "code" },
      //       dataSource: new DataManager({
      //         url: LOONITY_BASE_API_URL + "/api/geo/currency/",
      //         adaptor: new DrfAdaptor(),
      //         crossDomain: true,
      //       }),
      //       query : new Query().take(6),
      //     });
      //     multiSelectObj.appendTo(elem);
      //   },
      // },
      langTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template:
              '<p><span v-for="(lang, index) in langs">{{lang}}<span v-if="index+1 < langs.length">, </span></span></p>',
            computed: {
              langs: function () {
                let result = [];
                this.data.languages.forEach((element) => {
                  result.push(element.name);
                });
                return result;
              },
            },
          }),
        };
      },
    };
  },
});
</script>
